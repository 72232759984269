import React from 'react'
import { media, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import LazyHydrate from "react-lazy-hydration";

import { color } from '../basic/shared/styles';

import content from '../content';
import JourneyBlock from '../components/JourneyBlock';

const Wrapper = styled.div`
  padding-top: 500px;
`;

const RowWrap = styled(Row)`
  max-width: 1250px;
  margin: 0 auto;
  padding-top: 100px;
`;

const ColWrap = styled(Col)`
  margin: 0 auto;
`;

const Journey = () => (
    <Wrapper>
      <section id={'services'}>
        <RowWrap alignItems='center' justifyContent='center' noGutter >
          <ColWrap md={12} lg={4} noGutter >
            <JourneyBlock
              border={'left'}
              title={content.journey[0].title}
              subTitle={content.journey[0].subTitle}
              items={content.journey[0].items}
              btnText={content.journey[0].btn}
            />
          </ColWrap>
          <ColWrap md={12} lg={4} noGutter>
            <JourneyBlock
              border={'top'}
              title={content.journey[1].title}
              subTitle={content.journey[1].subTitle}
              items={content.journey[1].items}
              btnText={content.journey[1].btn}
            />
          </ColWrap>
          <ColWrap md={12} lg={4} noGutter>
            <JourneyBlock
              border={'right'}
              title={content.journey[2].title}
              subTitle={content.journey[2].subTitle}
              items={content.journey[2].items}
              btnText={content.journey[2].btn}
            />
          </ColWrap>
        </RowWrap>
      </section>
    </Wrapper>
);

export default Journey;
