import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Intercom from 'react-intercom';
import { BaseCSS } from 'styled-bootstrap-grid';
import { CookiesProvider, withCookies } from 'react-cookie';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Menu from '../components/Menu';
import Hero from '../layout/Hero';
import DividerTitle from '../layout/DividerTitle';
import Journey from '../layout/Journey';
import CtaOne from '../layout/CtaOne';
import Quotes from '../layout/Quotes';
import Platform from '../layout/Platform';
import Work from '../layout/Work';
// import CtaTwo from '../layout/CtaTwo';
// import NpsChart from '../layout/NpsChart';
// import CtaThree from '../layout/CtaThree';
import Contact from '../layout/Contact';
import SocialProof from '../components/SocialProof';
import Cookies from '../components/Cookies';
import content from '../content';

const IntercomR = (props) => (
  <Intercom appID="rhu8gzvn" mrm_cookie_id={props.cookies.get('mrm_cookie_id')} />
);
const IntercomC = withCookies(IntercomR);

const IndexPage = (props) => (
  <CookiesProvider>
      <StaticQuery
        query={graphql`
          query siteMetadataQuery {
            site {
              siteMetadata {
                title
                description
                author
                background_color
                theme_color
                keywords
                lang
                siteUrl
                metaImage {
                  image
                  height
                  width
                  alt
                }
              }
            }
          }
        `}
        render={data => {
          return (
          <>
            <BaseCSS />
            <Cookies page={props.location ? props.location.href : 'storybook'} />
            <Menu />
            <Layout>
              <SEO
                description={data.site.siteMetadata.description}
                title={data.site.siteMetadata.title}
                author={data.site.siteMetadata.author}
                lang={data.site.siteMetadata.lang}
                meta={data.site.siteMetadata.meta}
                keywords={data.site.siteMetadata.keywords}
                metaImage={data.site.siteMetadata.metaImage}
                siteUrl={data.site.siteMetadata.siteUrl}
              />
              <div style={{height: 70}}/>
              <section id={'home'}>
                <Hero />
              </section>
               <SocialProof
                monochrome
                grid
                heading="Trusted by"
                path="images/clients"
                brands={content.trust.companies.slice(0,100)}
              />
            </Layout>
            <DividerTitle 
              title={content.journey_title.title}
              content={[content.journey_title.subTitle, content.journey_title.liner]}
            />
            <Layout inverse>
              <Journey/>
              <Quotes />
              <Platform />
              <Work />
              <section id={'sontact'}>
                <CtaOne />
                <Contact />
              </section>
            </Layout>
            <IntercomC  />
            
              {/*
              
              <section id={'Services'}>
                <Services />
              </section>
             
              <CtaTwo />
              <NpsChart />
              <CtaThree />
              
               */}
          </>
        ) }}
      />
  </CookiesProvider>
);

export default IndexPage
