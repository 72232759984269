import React from 'react';
import styled from 'styled-components';
import { media, Row } from 'styled-bootstrap-grid';

import { color, typography } from '../basic/shared/styles';
import { Heading2, Heading6, Span1, Type1 } from '../basic/typography';
import Icon from '../basic/Icon';

const Wrap = styled.div`
  background-color: ${color.secondary};
  padding: 10px 0px;
  margin: 10px auto;
  border-radius: 30px;
  max-width: 500px;
  ${media.xs`
  border-radius: 30px;
  padding: 10px 0px;
  `}
  ${media.sm`
  border-radius: 30px;
  padding: 10px 0px;
  `}
  ${media.md`
  border-radius: 30px;
  padding: 20px 0px;
  `}
  ${media.lg`
  border-radius: ${props => props.border === 'left' ? '30px 0px 0px 30px' : props.border === 'right' ? '0px 30px 30px 0px' : '0px'};
  margin: 10px 3px;
  height: 500px;
  padding: 40px 0px;
  `}
`;
const HeadingWrap = styled.div`
  padding: 20px 0px 0px 0px;
  text-align: center;
`;
const Subtitle = styled(Type1)`
  text-align: center;
  padding: 10px 20px;
`;
const List = styled.div`
  padding: 0px 20px;
`;

const IconItem = styled(Icon)`
margin: 15px 10px;
${media.xs`
margin: 15px 10px;
`}
${media.sm`
margin: 15px 15px;
`}
${media.md`
margin: 15px 20px;
`}
${media.lg`
margin: 15px 20px;
`}
`;

const ItemWrap = styled(Row)`
`;

const JourneyBlock = ({border, icon, title, subTitle, items, btnText}) => (
  <Wrap border={border}>
    <HeadingWrap>
      <Heading2 color="appInverse" weight="black">
        {title}
      </Heading2>
    </HeadingWrap>
    <Subtitle color="primary" weight="black">
      {subTitle}
    </Subtitle>
    {/* <Bar/> */}
    <List>
      {items.map((item, i)=>(
        <ItemWrap key={i} alignItems='center' noGutter>
          <Span1 >
          <IconItem icon={item.icon} color={color.appInverse} width={30} height={30}/>
            {item.text}
          </Span1>
        </ItemWrap>
      ))}
    </List>
  </Wrap>
);


export default JourneyBlock;
