import React from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';

import HeroSlides from '../components/hero/HeroSlides';

const ContentContainer = styled.div`
  padding: 40px 40px 0px 40px;
  ${media.xs`
    padding: 10px 10px 0px 10px;
  `}
  ${media.sm`
    padding: 20px 20px 0px 20px;
  `}
`;
const Center = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

const Content = (props) => (
  <ContentContainer>
    <Center>
      <HeroSlides />
    </Center>
  </ContentContainer>
);

export default Content;
