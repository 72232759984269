import React, { useState, useEffect }  from 'react';
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';

import { color } from '../basic/shared/styles';
import Icon from '../basic/Icon';
import Quote from '../components/Quote';
import content from '../content';
const SLIDE_DURATION = 8000;

const QuotesWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 400px;
  ${media.xs`
  height: 550px;
  padding: 20px 0px;
  margin: 20px auto 0px auto;
  `}
  ${media.sm`
  height: 500px;
  margin: 20px auto  0px auto;
  `}
  ${media.md`
  height: 400px;
  margin: 80px auto 0px auto;
  `}
`;

const QuoteSlideST = styled.div`
  ${props => !props.visible && `
    opacity: 1;
  `}
  ${props => !!props.visible && `
    opacity: 0;
  `}
  height: 0px;
  transition: opacity 800ms ease-in-out;
`;
const RowWrap = styled(Row)`
  margin: 0 auto;
`;
const ColWrap = styled(Row)`
padding: 20px;
${media.xs`
visibility: hidden;
height: 0px;
`}
${media.sm`
visibility: hidden;
height: 0px;
`}
${media.md`
visibility: visible;
`}
`;
const IconWrap = styled(Icon)`
  cursor: pointer;
`;

const QuoteSlide = ({visible, index, ...props}) => (
  <QuoteSlideST visible={visible}>
    <Quote {...props}/>
  </QuoteSlideST>
);

const Quotes = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(()=>{
    const tmr = setTimeout(()=>{
      setCurrentIndex((currentIndex+1)%content.trust.quotes.length);
    }, SLIDE_DURATION);
    return () => clearTimeout(tmr);
  }, [currentIndex]);
  const incSlide = (()=>{
    setCurrentIndex((currentIndex+1)%content.trust.quotes.length);
  });
  const decSlide = (()=>{
    setCurrentIndex((currentIndex-1+content.trust.quotes.length)%content.trust.quotes.length);
  });
  return(
    <QuotesWrapper>
      <RowWrap alignItems='center' justifyContent='center' noGutter >
        <ColWrap sm={1} md={1} noGutter style={{textAlign: 'right'}}>
          <IconWrap height={50} width={50} onClick={()=> decSlide()} color={color.primary} icon='arrowleft' />
        </ColWrap>
        <Col sm={10} md={10} noGutter >
          <div style={{height: 250}}>
            {content.trust.quotes.map((item, index)=>(
              <QuoteSlide
                key={index}
                index={index}
                visible={currentIndex===index}
                quote={item.quote}
                name={item.name}
                job={item.job}
                company={item.company}
              />
            ))}
          </div>
        </Col>
        <ColWrap sm={1} md={1} noGutter>
          <IconWrap height={50} width={50} onClick={()=> incSlide()} color={color.primary} icon='arrowright' />
        </ColWrap>
      </RowWrap>
    </QuotesWrapper>
  );
};

export default Quotes;
