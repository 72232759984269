import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Row, Col } from 'styled-bootstrap-grid';

import content from '../../content';
import { color, typography } from '../../basic/shared/styles';
import { Heading1, Heading4 } from '../../basic/typography';
import EmailInput from '../EmailInput';
import Hero1 from '../../images/hero1.png';

const SLIDE_DURATION = 3000;

const HeroSlideST = styled.div`
`;

const HeadingST = styled(Heading1)`
  ${props => !props.visible && `
    opacity: 1;
  `}
  ${props => !!props.visible && `
    opacity: 0;
  `}
  position: absolute;
  transition: opacity 800ms ease-in-out;
  margin: 0px 0px;
  ${media.sm`
    margin: 0px 0px;
  `}
  ${media.md`
    margin: 40px 0px 120px 0px;
  `}
`;

const SlideCenter = styled(Row)`
  margin: 0 auto;
  max-width: 1450px;
  width: 100%;
`;

const RectangleSM = styled.div`
  display: block;
  margin: 20px 0px;
  ${media.xs`
  display: block;
  `}
  ${media.sm`
  display: block;
  `}
  ${media.md`
  display: none;
  `}
  ${media.lg`
  display: none;
  `}
  ${media.xl`
  display: none;
  `}
`;

const RectangleMD = styled.div`
  display: none;
  margin: 50px 0px 0px;
  ${media.xs`
  display: none;
  margin: 50px 10px 0px;
  `}
  ${media.sm`
  display: none;
  margin: 50px 20px 0px;
  `}
  ${media.md`
  display: block;
  margin: 50px 30px 0px;
  `}
  ${media.lg`
  display: block;
  margin: 50px;
  `}
  ${media.xl`
  display: block;
  margin: 50px;
  `}
`;
const EmailWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 50px;
  ${media.sm`
    padding-top: 30px;
  `}
  ${media.md`
    padding-top: 50px;
  `}
`;

const HeadingBox = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 0px 0px 0px 10px;
  ${media.sm`
    padding: 0px 0px 0px 40px;
  `}
  ${media.md`
    padding: 0px 0px 0px 40px;
  `}
`;

const HeadingOneBox = styled.div`
  height: 260px;
  ${media.xs`
    height: 250px;
  `}
  ${media.sm`
    height: 190px;
  `}
  ${media.md`
    height: 260px;
  `}
  ${media.lg`
    height: 240px;
  `}
`;

const titles = [...content.hero.titles];

const HeroSlide = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(()=>{
    setTimeout(()=>{
      setCurrentIndex((currentIndex+1)%titles.length);
    }, SLIDE_DURATION);
  });
  return (
    <HeroSlideST>
      <SlideCenter>
        <Col sm={12} md={6}>
          <HeadingBox>
            <HeadingOneBox>
              {titles.map((item, index)=>
                <HeadingST key={index} color="primary" weight="bold" visible={currentIndex===index}>{item}</HeadingST>
              )}
            </HeadingOneBox>
            <RectangleSM>
              <img src={Hero1} alt='MRMTECH' width='100%'/>
            </RectangleSM>
            <Heading4 color="secondary" >{content.hero.subtitle}</Heading4>
            <EmailWrapper>
              <EmailInput zone='hero' />
            </EmailWrapper>
          </HeadingBox>
        </Col>
        <Col sm={12} md={6}>
          <RectangleMD>
            <img src={Hero1} alt='MRMTECH' width='100%'/>
          </RectangleMD>
        </Col>
      </SlideCenter>
    </HeroSlideST>
)};

export default HeroSlide;
