import React from 'react'
import styled from 'styled-components';
import { media, Row, Col } from 'styled-bootstrap-grid';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import { Heading6, Heading3, Type1, Type2 } from '../basic/typography';
import { color } from '../basic/shared/styles';
import Button from '../basic/Button';
import Link from '../basic/Link';
import content from '../content';
import Icon from '../basic/Icon';
import CS1 from '../images/casestudies/cs_avis_vr.png';
import CS2 from '../images/casestudies/cs_mrm.png';
import CS3 from '../images/casestudies/cs_sony.png';
import CS4 from '../images/casestudies/cs_lexus.png';

const images = [CS1, CS2, CS3, CS4];

const Wrapper = styled.div`
  padding: 20px;
`;
const ColWrap = styled(Col)`
  margin: 80px 0 20px 0px;
  background: ${color.secondary};
  ${media.xs`
  margin: 80px 0 0px 0px;
`}
${media.sm`
  margin: 80px 0 0px 0px;
`}
${media.md`
  margin: 80px 0 20px -30px;
`}
`;

const PreTitle = styled(Heading6)`
`;
const Title = styled(Heading3)`
`;
const SubTitle = styled(Heading6)`
`;
const Description = styled(Type1)`
`;
const BtnWrap = styled.div`
`;
const Img = styled.div`
height: 200px;
width: 300px;
margin: 20px auto;
`;

const SliderST = styled(Slider)`
 .slick-prev:before, .slick-next:before  {
  content: '' !important;
  }
`;
const SlideWrap = styled.div`
padding: 10px;
outline: -webkit-focus-ring-color auto 0px;
${media.xs`
padding: 10px;
`}
${media.sm`
padding: 40px;
`}
${media.md`
padding: 40px;
`}
`;
const WorkWrapper = styled.div`
  padding: 10px;
  overflow: hidden;
${media.xs`
padding: 30px 30px;
`}
${media.sm`
padding: 30px 30px;
`}
${media.md`
padding: 40px 40px;
`}
${media.lg`
padding: 60px;
`}
`;
const RightWrap = styled.div`
cursor: pointer;
right: -5px;
${media.xs`
right: -5px !important;
`}
${media.sm`
right: -5px !important;
`}
${media.md`
right: -25px;
`}
${media.lg`
right: -25px;
`}
`;
const LeftWrap = styled.div`
cursor: pointer;
`;

const Right = ({ className, style, onClick }) => (
  <RightWrap
    className={className}
    onClick={onClick}
  >
    <Icon height={40} width={40} icon='arrowright' color={color.primary}/>
  </RightWrap>
);
const Left = ({ className, style, onClick }) => (
  <LeftWrap
    className={className}
    onClick={onClick}
  >
    <Icon height={40} width={40} icon='arrowleft' color={color.primary}/>
  </LeftWrap>
);

const PlatformText = () => {
  const settings = {
    className: 'center',
    centerPadding: '120px',
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 6000,
    cssEase: "linear",
    nextArrow: <Right/>,
    prevArrow: <Left/>,
    arrows: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <WorkWrapper>
      <PreTitle color='primary' weight='black'>Our Projects</PreTitle>
      <Title color='appInverse'>See what we have been up to</Title>
      <SliderST {...settings}>
        {content.caseStudies.studies.map( (study, index)  => (
          <SlideWrap key={index}>
            <Img>
              <img src={images[index]} alt={study.title} width='100%'/>
            </Img>
            <SubTitle>{study.title}</SubTitle>
            <Description>
              <Type2>{study.paragraphs[0]} <Link to='/studies' color={color.primary} ><b>Read more.</b></Link></Type2>
            </Description>
            {/* { study.file && 
              <form method="get" target="_blank" action={study.file}>
                <Button type='submit'>Download</Button>
              </form>
            } */}
          </SlideWrap>
        ))}
      </SliderST>
    </WorkWrapper>
  );
};

const Work = () => (
  <Wrapper>
    <Row noGutter >
      <ColWrap sm={12} md={10} noGutter >
        <PlatformText />
      </ColWrap>
      <Col sm={12} md={2} noGutter>
      </Col>
    </Row>
  </Wrapper>
);

export default Work;
