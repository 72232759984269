import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { Heading6, Heading1 } from '../basic/typography';
import { color } from '../basic/shared/styles';
import content from '../content';

import Platform1 from '../images/platform1.png';

const Wrapper = styled.div`
  padding: 20px;
`;

const RowWrap = styled(Row)`
  margin: 0 auto;
`;

const PlatformDrawingST = styled.div`
padding: 20px;
`;
const PlatformTextWrapper = styled.div`
  text-align: ${props => props.align};
`;
const ListItem = styled(Heading6)`
  padding: 10px;
`;

const PlatformTitle = styled(Heading1)`
  padding: 20px 0px;
  text-align: center;
`;

const PlatformText = ({align, list}) => (
  <PlatformTextWrapper align={align}>
    {list.map((text, i) =>
      <ListItem key={i} color="secondary">{text}</ListItem>
    )}
  </PlatformTextWrapper>
);

const calc = (x, y) => [-(y - window.innerHeight / 2) / 60, (x - window.innerWidth / 2) / 60, 1.05];
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;


const PlatformDrawing = ({}) => {
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));
  console.log(Platform1)
  return (
  <animated.div
    onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
    onMouseLeave={() => set({ xys: [0, 0, 1] })}
    style={{ transform: props.xys.interpolate(trans) }}
  >
    <PlatformDrawingST>
      <img src={Platform1} alt='MRMTechApp' width='100%' />
    </PlatformDrawingST>
  </animated.div>
)};



const Platform = () => (
    <Wrapper>
      <PlatformTitle color='primary' weight='black'>{content.feature.title}</PlatformTitle>
      <RowWrap alignItems='center' justifyContent='center' noGutter >
        <Col sm={12} md={3} lg={4} noGutter >
        <PlatformText align='right' list={content.platform.listLeft} />
        </Col>
        <Col sm={12} md={6} lg={4} noGutter>
        <PlatformDrawing />
        </Col>
        <Col sm={12} md={3} lg={4}  noGutter>
        <PlatformText align='left' list={content.platform.listRight}  />
        </Col>
      </RowWrap>
    </Wrapper>
);

export default Platform;
