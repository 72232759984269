import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { color, typography } from '../basic/shared/styles';
import { Heading6, Type2 } from '../basic/typography';

const QuoteWrapper = styled.div`
  background: ${color.primary};
  padding: 30px;
  border-radius: 30px 0 30px 0;
`;

const QuoteLine = styled(Heading6)`
  padding: 10px;
`;

const Source = styled.div`
  text-align: right;
  padding-right: 30px;
  font-size: ${typography.size.s2}px;
`;

const Person = styled(Type2)`
  display: inline-block;
`;

const Logo = styled.img`
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 80px;
  max-height: 24px;
  object-fit: contain;
  ${props =>
    props.monochrome &&
    css`
      opacity: 0.4;
      filter: grayscale(100%);
    `};
`;

const LogoWrapper = styled.div`
  padding: 10px;
  display: inline-block;
  text-align: center;
`;


const Quote = ({name, quote, job, company}) => (
  <QuoteWrapper>
    <QuoteLine color="secondary">
      "{quote}"
    </QuoteLine>
    <Source>
      <Person>
        {name}<br/>{job}
      </Person>
      <LogoWrapper key={company} title={company}>
        <Logo src={`images/clients/logo-${company}.png`} alt={company} monochrome={false} />
      </LogoWrapper>
    </Source>
  </QuoteWrapper>
);

Quote.propTypes = {
  name: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
};

export default Quote;
